import _package from "../package.json";
import _utils from "./elliptic/utils";
import _brorand from "brorand";
import _curve from "./elliptic/curve";
import _curves from "./elliptic/curves";
import _ec from "./elliptic/ec";
import _eddsa from "./elliptic/eddsa";
var exports = {};
var elliptic = exports;
elliptic.version = _package.version;
elliptic.utils = _utils;
elliptic.rand = _brorand;
elliptic.curve = _curve;
elliptic.curves = _curves; // Protocols

elliptic.ec = _ec;
elliptic.eddsa = _eddsa;
export default exports;